<template>
  <v-container>
    <v-card>
      <v-card-title>
        New Layout
      </v-card-title>
      <validation-observer ref="form" v-slot="{ valid }" tag="form" @submit.prevent="next">
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-alert
                v-if="error"
                dense
                outlined
                border="left"
                type="error"
                class="mb-0"
              >
                {{ error.message }}
              </v-alert>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:5"
                    name="Name"
                  >
                    <v-text-field
                      v-model="form.name"
                      label="Layout Name"
                      type="text"
                      outlined
                      prepend-icon="mdi-tag"
                      :error-messages="errors"
                      required
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6" xs="12">
                  <v-row>
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Device"
                      >
                        <v-select
                          v-model="form.deviceId"
                          :items="[{ name: 'Custom', id: 'custom' }, ...$store.getters.devices]"
                          item-text="name"
                          item-value="id"
                          label="Device"
                          type="text"
                          outlined
                          prepend-icon="mdi-tablet-cellphone"
                          :error-messages="errors"
                          required
                          @change="updateResolution"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" xs="12">
                  <v-row>
                    <v-col cols="6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Width"
                      >
                        <v-text-field
                          v-model="form.resolution.width"
                          label="Width"
                          type="number"
                          outlined
                          prepend-icon="mdi-arrow-expand-horizontal"
                          :error-messages="errors"
                          required
                          :disabled="form.deviceId && form.deviceId !== 'custom'"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="6">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Height"
                      >
                        <v-text-field
                          v-model="form.resolution.height"
                          label="Height"
                          type="number"
                          outlined
                          prepend-icon="mdi-arrow-expand-vertical"
                          :error-messages="errors"
                          required
                          :disabled="form.deviceId && form.deviceId !== 'custom'"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-img
                v-if="form.resolution.width && form.resolution.height"
                src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPU17f9DwACzAGcAfOwlwAAAABJRU5ErkJggg=="
                gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                :aspect-ratio="form.resolution.width / form.resolution.height"
              >
                <v-container class="fill-height flex-column align-center justify-center white--text">
                  <div class="font-weight-bold">
                    {{ selectedDevice ? selectedDevice.name : 'Custom' }}
                  </div>
                  <div>
                    {{ form.resolution | formatResolution }}
                  </div>
                </v-container>
              </v-img>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-3">
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            type="submit"
            :loading="loading"
            :disabled="error !== null || !valid || loading"
          >
            Next
            <v-icon right>
              mdi-arrow-right
            </v-icon>
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'LayoutForm',
  data: () => ({
    loading: false,
    form: {
      name: '',
      deviceId: 'custom',
      resolution: {
        width: '',
        height: ''
      }
    },
    error: null
  }),
  computed: {
    selectedDevice() {
      const deviceId = this.form.deviceId
      if (deviceId && deviceId !== 'custom') {
        return this.$store.getters.deviceById(deviceId)
      }
      return null
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.error = null
      }
    }
  },
  mounted() {
    const { displayName, width, height } = this.$route.params
    if (displayName) {
      this.form.name = `Layout for ${displayName}`
    }
    if (width && height) {
      this.form.deviceId = 'custom'
      this.form.resolution.width = width
      this.form.resolution.height = height
    }
  },
  methods: {
    updateResolution() {
      if (this.selectedDevice) {
        this.form.resolution.width = this.selectedDevice.resolution.width
        this.form.resolution.height = this.selectedDevice.resolution.height
      }
    },
    async next() {
      const form = this.$refs.form
      const formIsValid = await form.validate()
      if (!formIsValid) { return }
      this.loading = true
      try {
        const { name, deviceId, resolution } = this.form
        const newLayoutRef = await this.$db.collection('layouts').add({
          name,
          userId: this.$store.getters.user.uid,
          organizationId: this.$store.getters.organization.id,
          deviceId,
          resolution,
          createdAt: this.$nowstamp(),
          updatedAt: this.$nowstamp()
        })
        // eslint-disable-next-line
        console.log('new id', newLayoutRef.id)
        const { displayId } = this.$route.params
        if (displayId) {
          await this.$db.collection('displays').doc(displayId).update({
            layoutId: newLayoutRef.id
          })
        }
        this.$router.push(`/layouts/${newLayoutRef.id}`)
      } catch (error) {
        this.error = error
      }
      this.loading = false
    }
  }
}
</script>
