var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" New Layout ")]),_c('validation-observer',{ref:"form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.next.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[(_vm.error)?_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","outlined":"","border":"left","type":"error"}},[_vm._v(" "+_vm._s(_vm.error.message)+" ")]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|min:5","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Layout Name","type":"text","outlined":"","prepend-icon":"mdi-tag","error-messages":errors,"required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6","xs":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Device"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":[{ name: 'Custom', id: 'custom' } ].concat( _vm.$store.getters.devices),"item-text":"name","item-value":"id","label":"Device","type":"text","outlined":"","prepend-icon":"mdi-tablet-cellphone","error-messages":errors,"required":""},on:{"change":_vm.updateResolution},model:{value:(_vm.form.deviceId),callback:function ($$v) {_vm.$set(_vm.form, "deviceId", $$v)},expression:"form.deviceId"}})]}}],null,true)})],1)],1)],1),_c('v-col',{attrs:{"cols":"6","xs":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Width","type":"number","outlined":"","prepend-icon":"mdi-arrow-expand-horizontal","error-messages":errors,"required":"","disabled":_vm.form.deviceId && _vm.form.deviceId !== 'custom'},model:{value:(_vm.form.resolution.width),callback:function ($$v) {_vm.$set(_vm.form.resolution, "width", $$v)},expression:"form.resolution.width"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Height"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Height","type":"number","outlined":"","prepend-icon":"mdi-arrow-expand-vertical","error-messages":errors,"required":"","disabled":_vm.form.deviceId && _vm.form.deviceId !== 'custom'},model:{value:(_vm.form.resolution.height),callback:function ($$v) {_vm.$set(_vm.form.resolution, "height", $$v)},expression:"form.resolution.height"}})]}}],null,true)})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[(_vm.form.resolution.width && _vm.form.resolution.height)?_c('v-img',{attrs:{"src":"data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPU17f9DwACzAGcAfOwlwAAAABJRU5ErkJggg==","gradient":"to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)","aspect-ratio":_vm.form.resolution.width / _vm.form.resolution.height}},[_c('v-container',{staticClass:"fill-height flex-column align-center justify-center white--text"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.selectedDevice ? _vm.selectedDevice.name : 'Custom')+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("formatResolution")(_vm.form.resolution))+" ")])])],1):_vm._e()],1)],1)],1),_c('v-card-actions',{staticClass:"px-3"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","outlined":"","type":"submit","loading":_vm.loading,"disabled":_vm.error !== null || !valid || _vm.loading}},[_vm._v(" Next "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-arrow-right ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }